import React from "react";
import Message from './Message'
import * as t from 'io-ts'

type MessageType = t.TypeOf<typeof Message>

function Preview(): JSX.Element {
  return <div style={{background: '#111', color: '#fff'}}>
    <h1 style={{margin: 0, textAlign: 'center', padding: 12, fontSize: 16}}>Trippy News</h1>

    <a href="/">Breaking News: California State Senate Passes Psychedelic Legalization Bill</a>
    <p>After Senate approval, SB 519 is headed to the State Assembly for a final vote before it can become California law. Read on at DoubleBlind.</p>

    <a href="/">Psychedelics Aren’t Just Medicine, but Tools for “Human Enhancement”</a>
    <p>Psychedelics for the “betterment of well people” isn’t a new notion, but recent legislation is giving the idea new life.</p>
  </div>
}

function SignUpForm(): JSX.Element {
  const [email, setEmail] = React.useState("");

  async function onSubmit(ev: React.SyntheticEvent): Promise<void> {
    ev.preventDefault();

    const message: MessageType = {
      method: 'createAccount',
      email,
    }

    fbq('track', 'CompleteRegistration', {});

    setEmail("");

    await fetch('https://api.trippy-news.workers.dev', {
      method: 'POST',
      body: JSON.stringify(message)
    })
  }

  function onChangeEmail(ev: React.ChangeEvent<HTMLInputElement>): void {
    setEmail(ev.target.value);
  }

  return (
    <form className="sign-up-form" onSubmit={onSubmit}>
      <input
        className="sign-up-form__email"
        type="email"
        placeholder="albert_hofmann@sandoz.com"
        value={email}
        onChange={onChangeEmail}
      />
      <button className="sign-up-form__button" type="submit">
        Subscribe
      </button>
    </form>
  );
}

function RootNewsletter(): JSX.Element {
  const countSubscribers = "1,367";
  const countIssues = "56";

  const [isPrivacyPolicyShown, setIsPrivacyPolicyShown] = React.useState(false);

  function onClickPrivacyPolicy(ev: React.MouseEvent): void {
    ev.preventDefault();

    setIsPrivacyPolicyShown(true);
  }

  return (
    <div className="main">
      <div className="left">
        <div className="social-proof">
          <img className="social-proof__img" src="img/face_9.jpeg" alt="" />
          <img className="social-proof__img" src="img/face_8.jpeg" alt="" />
          <img className="social-proof__img" src="img/face_7.jpeg" alt="" />
          <img className="social-proof__img" src="img/face_6.jpeg" alt="" />
          <img className="social-proof__img" src="img/face_5.jpeg" alt="" />
          <img className="social-proof__img" src="img/face_4.jpeg" alt="" />
          <img className="social-proof__img" src="img/face_3.jpeg" alt="" />
          <img className="social-proof__img" src="img/face_2.jpeg" alt="" />
          <img className="social-proof__img" src="img/face_17.jpeg" alt="" />
          <img className="social-proof__img" src="img/face_16.jpeg" alt="" />
          <img className="social-proof__img" src="img/face_15.jpeg" alt="" />
        </div>
        <p className="lead">
          A research-focused <strong>biweekly newsletter</strong> covering
          stories about the <strong>expansion of psychedelics</strong> around
          the globe.
        </p>
        <SignUpForm />
        <div>
          <small>
            {countSubscribers} subscribers - {countIssues} issues
          </small>
        </div>
        <p className="disclaimer">
          Easy to unsubscribe at any time. Your e-mail address is safe — here's
          our{" "}
          <a className="link" href="/" onClick={onClickPrivacyPolicy}>
            privacy policy
          </a>
          .
        </p>
        {isPrivacyPolicyShown ? (
          <div className="privacy-policy">
            <ol className="privacy-policy__list">
              <li className="privacy-policy__list-item">
                We are never going to sell your data.
              </li>
              <li className="privacy-policy__list-item">
                We have no idea how to sell your data to begin with.
              </li>
              <li className="privacy-policy__list-item">
                You can unsubscribe at any time.
              </li>
              <li className="privacy-policy__list-item">
                If you have any questions, feel free to{" "}
                <a
                  className="link"
                  href="https://alex143.typeform.com/to/Esz5c0E0"
                  target="_blank"
                  rel="noreferrer"
                >
                  contact us
                </a>
                .
              </li>
            </ol>
          </div>
        ) : null}
      </div>
      <div className="right">
{/*        <div className="marvel-device iphone8plus silver">
          <div className="top-bar"></div>
          <div className="sleep"></div>
          <div className="volume"></div>
          <div className="camera"></div>
          <div className="sensor"></div>
          <div className="speaker"></div>
          <div className="screen">
            <Preview />
          </div>
          <div className="home"></div>
          <div className="bottom-bar"></div>
      </div>
*/}      </div>
    </div>
  );
}

function App(): JSX.Element | null {
  return null;
  // return <RootNewsletter />;
}

export default App;

